<template>
  <div class="reset-password-container">
    <transition name="appear" mode="out-in">
      <div v-if="passwordReset" data-external="reset-password-success-div" class="password-reset">
        <FriedH2 class="title branding">{{ $t('password-has-changed') }}</FriedH2>
        <FriedParagraph class="description">
          {{ $t('password-has-changed-description') }}
        </FriedParagraph>
        <FriedButton
          class="reset-button"
          data-external="reset-password-login-button"
          @click="handleLoginClick"
        >
          {{ $t('login') }}
        </FriedButton>
      </div>
      <div v-else class="reset-password">
        <FriedH2 class="title branding">{{ $t('create-new-password') }}</FriedH2>
        <FriedParagraph class="description">
          {{ $t('create-new-password-description') }}
        </FriedParagraph>
        <FriedMessage
          v-if="error"
          data-external="reset-password-error-div"
          class="error"
          :type="MessageType.Error"
          :title="$t(error.title)"
        >
          <i18n-t :keypath="error.description">
            <FriedLink
              :normal="true"
              place="contact-support-link"
              href="http://help.getaccept.com/"
              target="_blank"
            >
              {{ $t('contact-support') }}
            </FriedLink>
          </i18n-t>
        </FriedMessage>
        <form @submit.prevent="handleSubmit">
          <FriedInput
            v-model="newPassword"
            data-external="reset-password-new-password-input"
            :type="newPasswordInputType"
            class="login-input"
            :label="$t('new-password')"
            :error-message="errorMessage"
            autocomplete="new-password"
            autofocus
          >
            <template #append>
              <button
                data-external="reset-password-toggle-view-new-password-button"
                type="button"
                class="view-password-button"
                :title="$t('show-and-hide-password')"
                tabindex="-1"
                @click.prevent="toggleViewNewPassword"
              >
                <FriedIcon v-if="!viewNewPassword" key="1" aria-hidden icon="view" />
                <FriedIcon v-else key="2" aria-hidden icon="view-hide" />
              </button>
            </template>
          </FriedInput>
          <FriedInput
            v-model="newPasswordRepeat"
            data-external="reset-password-new-password-repeat-input"
            :type="newPasswordRepeatInputType"
            class="login-input"
            :label="$t('confirm-password')"
            :help-message="$t('password-must-include')"
            :error-message="errorInputMessage"
            autocomplete="confirm-new-password"
          >
            <template #append>
              <button
                data-external="reset-password-toggle-view-confirm-password-button"
                type="button"
                tabindex="-1"
                class="view-password-button"
                :title="$t('show-and-hide-password')"
                @click.prevent="toggleViewNewPasswordRepeat"
              >
                <FriedIcon v-if="!viewNewPasswordRepeat" key="1" aria-hidden icon="view" />
                <FriedIcon v-else key="2" aria-hidden icon="view-hide" />
              </button>
            </template>
          </FriedInput>
          <PasswordValidator
            class="password-validator"
            :input="newPassword"
            :show-errors="showValidatorError"
            @is-valid-password="handleIsValidPassword"
          />
          <FriedButton
            class="reset-button"
            data-external="reset-password-submit-button"
            :loading="loading"
            type="submit"
            >{{ $t('reset-password') }}</FriedButton
          >
        </form>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { InputType, MessageType } from '@getaccept/fried-tofu';
import type { AxiosError } from 'axios';

import { RecaptchaAction } from '@getaccept/lib-shared/src/recaptcha/recaptcha-action';
import type { Ref } from 'vue';
import { watch, computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import bugsnagClient from '@getaccept/lib-shared/src/bugsnag';
import { ResetPasswordError } from '../types/enums/reset-password-error';
import type { ErrorMessage } from '../../types/error-message';
import { PasswordValidation } from '../../helpers/password-validation';
import type { ResetResponse } from '../../api/login/types/reset-response';
import { LoginService } from '../../api/login/login.service';
import { RecaptchaService } from '../../api/recaptcha/recaptcha.service';
import { ErrorKey } from '../../types/enums/error-key';
import { getErrorMessage } from '../../helpers/error-message';
import PasswordValidator from '../../common/components/PasswordValidator.vue';

export default defineComponent({
  components: { PasswordValidator },
  setup() {
    const router = useRouter();
    const newPassword = ref('');
    const newPasswordRepeat = ref('');
    const viewNewPassword = ref(false);
    const viewNewPasswordRepeat = ref(false);
    const errorMessage = ref('');
    const passwordMatchError = ref('');
    const showValidatorError = ref(false);
    const isValidPassword = ref(false);
    const error: Ref<ErrorMessage | null> = ref(null);
    const loading = ref(false);
    const passwordReset = ref(false);
    const userId = ref('');
    const passwordKey = ref('');
    watch(newPassword, () => {
      errorMessage.value = '';
      passwordMatchError.value = '';
    });
    watch(newPasswordRepeat, () => {
      passwordMatchError.value = '';
    });
    const errorInputMessage = computed(() => errorMessage.value || passwordMatchError.value || '');
    const newPasswordInputType = computed(() =>
      viewNewPassword.value ? InputType.Text : InputType.Password
    );
    const newPasswordRepeatInputType = computed(() =>
      viewNewPasswordRepeat.value ? InputType.Text : InputType.Password
    );
    const handleIsValidPassword = (isValid: boolean) => {
      isValidPassword.value = isValid;
    };
    const toggleViewNewPassword = () => {
      viewNewPassword.value = !viewNewPassword.value;
    };
    const toggleViewNewPasswordRepeat = () => {
      viewNewPasswordRepeat.value = !viewNewPasswordRepeat.value;
    };
    const handleLoginClick = () => {
      router.push({ name: 'login' });
    };
    const handleSubmit = () => {
      showValidatorError.value = true;
      errorMessage.value = PasswordValidation.validateNewPassword(
        newPassword.value,
        isValidPassword.value
      );
      passwordMatchError.value = PasswordValidation.validateNewPasswordRepeat(
        newPassword.value,
        newPasswordRepeat.value
      );
      if (errorMessage.value || passwordMatchError.value) {
        return;
      }
      resetPassword({
        newPassword: newPassword.value,
        newPasswordRepeat: newPasswordRepeat.value,
      });
    };
    const resetPassword = async ({
      newPassword,
      newPasswordRepeat,
    }: {
      newPassword: string;
      newPasswordRepeat: string;
    }) => {
      try {
        loading.value = true;
        const { userId, passwordKey } = router.currentRoute.value.params;
        const recaptchaToken: string = await RecaptchaService.getToken(RecaptchaAction.Reset);
        const data: ResetResponse = await LoginService.reset({
          newPassword,
          newPasswordRepeat,
          passwordKey: passwordKey as string,
          userId: userId as string,
          recaptchaToken,
        });
        if (data.status === 0) {
          resetPasswordFailed({ data });
          loading.value = false;
          return;
        }
        resetPasswordSuccess();
        loading.value = false;
      } catch (error) {
        resetPasswordFailed({ requestError: error });
        loading.value = false;
      }
    };
    const resetPasswordFailed = ({
      requestError,
      data,
    }: {
      requestError?: AxiosError;
      data?: ResetResponse;
    }) => {
      const errorMessage = data?.error;
      let newError: ErrorMessage;
      switch (errorMessage) {
        case ResetPasswordError.Throttled:
          newError = getErrorMessage(ErrorKey.Throttled);
          break;
        case ResetPasswordError.InvalidId:
        case ResetPasswordError.InvalidKeyOrPassword:
          newError = getErrorMessage(ErrorKey.InvalidKey);
          if (bugsnagClient) {
            bugsnagClient.notify(new Error(JSON.stringify(data)), event => {
              event.groupingHash = JSON.stringify(data);
            });
          }
          break;
        case ResetPasswordError.PasswordWeak:
          newError = getErrorMessage(ErrorKey.WeakPassword);
          break;
        case ResetPasswordError.CouldNotUpdate:
        default:
          newError = getErrorMessage(ErrorKey.Unknown);
          if (bugsnagClient && requestError) {
            bugsnagClient.notify(requestError, event => {
              event.groupingHash = JSON.stringify(requestError);
            });
          }
      }
      error.value = newError;
    };
    const resetPasswordSuccess = () => {
      passwordReset.value = true;
    };
    return {
      newPassword,
      newPasswordRepeat,
      viewNewPassword,
      viewNewPasswordRepeat,
      errorMessage,
      passwordMatchError,
      showValidatorError,
      isValidPassword,
      error,
      loading,
      passwordReset,
      userId,
      passwordKey,
      errorInputMessage,
      newPasswordInputType,
      newPasswordRepeatInputType,
      handleIsValidPassword,
      toggleViewNewPassword,
      toggleViewNewPasswordRepeat,
      handleLoginClick,
      handleSubmit,
      MessageType,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-input {
  width: 100%;
  margin-bottom: var(--spacing-50);
}

.title {
  margin-bottom: var(--spacing-100);
}

.description {
  color: var(--text-gray);
  padding-bottom: var(--spacing-150);
}

.password-validator {
  margin-top: var(--spacing-50);
  margin-bottom: var(--spacing-150);
}

.error {
  margin-bottom: var(--spacing-50);
}

.view-password-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;

  &:focus:not([data-focus-visible-added]) {
    outline: none;
  }
}

.reset-button {
  width: 100%;
}
</style>
