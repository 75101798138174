import axios from 'axios';
import type { ResetResponse } from './types/reset-response';
import type { RequestResponse } from './types/request-response';
import type { LoginResponseDTO } from './types/login-response-dto';
import type { LoginResponse } from './types/login-response';

export class LoginService {
  private static readonly url = '/get/login';

  static async login({
    email,
    password,
    recaptchaToken,
    connectIdentity,
  }: {
    email: string;
    password: string;
    recaptchaToken: string;
    connectIdentity: string;
  }): Promise<LoginResponse> {
    const body = new FormData();
    body.append('action', 'login');
    body.append('recaptcha_response', recaptchaToken);
    body.append('login-email', email);
    body.append('login-password', password);
    body.append('user-connect-identity', connectIdentity);
    const { data } = await axios.post(LoginService.url, body);
    return this.convertLoginResponse(data);
  }

  static async request({
    email,
    recaptchaToken,
  }: {
    email: string;
    recaptchaToken: string;
  }): Promise<RequestResponse> {
    const body = new FormData();
    body.append('action', 'request');
    body.append('recaptcha_response', recaptchaToken);
    body.append('request-email', email);

    const { data } = await axios.post(LoginService.url, body);
    return data;
  }

  static async reset({
    newPassword,
    newPasswordRepeat,
    passwordKey,
    userId,
    recaptchaToken,
  }: {
    newPassword: string;
    newPasswordRepeat: string;
    passwordKey: string;
    userId: string;
    recaptchaToken: string;
  }): Promise<ResetResponse> {
    const body = new FormData();
    body.append('action', 'reset');
    body.append('new-password', newPassword);
    body.append('new-password-repeat', newPasswordRepeat);
    body.append('reset-password-key', passwordKey);
    body.append('user-id', userId);
    body.append('recaptcha_response', recaptchaToken);

    const { data } = await axios.post(LoginService.url, body);
    return data;
  }

  static async expired({
    oldPassword,
    newPassword,
    newPasswordRepeat,
    email,
    recaptchaToken,
  }: {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    email: string;
    recaptchaToken: string;
  }): Promise<LoginResponse> {
    const body = new FormData();
    body.append('action', 'expired');
    body.append('old-password', oldPassword);
    body.append('new-password', newPassword);
    body.append('new-password-repeat', newPasswordRepeat);
    body.append('email', email);
    body.append('recaptcha_response', recaptchaToken);

    const { data } = await axios.post(LoginService.url, body);
    return this.convertLoginResponse(data);
  }

  private static convertLoginResponse(response: LoginResponseDTO): LoginResponse {
    return {
      status: response.status,
      enforceBeta: !!response.enforce_beta,
      entityCount: response.entity_count,
      authMethod: response.auth_method || undefined,
      message: response.message || undefined,
      error: response.error || undefined,
      authEntityId: response.auth_entity_id || undefined,
    };
  }

  static async getSAMLUrl(domainName: string): Promise<string> {
    const url = `https://${import.meta.env.VUE_APP_INTERNAL_API_BASE_URL}/auth/saml/${domainName}`;
    const { data } = await axios.get(url);
    return data?.redirectUrl;
  }

  static async verifyLoginOTP({
    otp,
    recaptchaToken,
  }: {
    otp: string;
    recaptchaToken: string;
  }): Promise<LoginResponse> {
    const body = new FormData();
    body.append('action', 'verify-login-otp');
    body.append('otp', otp);
    body.append('recaptcha_response', recaptchaToken);

    const { data } = await axios.post(LoginService.url, body);
    return data;
  }

  static async generateNewLoginOTP(recaptchaToken: string): Promise<LoginResponse> {
    const body = new FormData();
    body.append('recaptcha_response', recaptchaToken);
    body.append('action', 'generate-new-login-otp');

    const { data } = await axios.post(LoginService.url, body);
    return data;
  }
}
